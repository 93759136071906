<script setup>

  /**
   * 
   * Komponens: kosár modal ablak cím sor
   * 
  */

  const { inventories, publicationdate  } = defineProps({
    inventories: { type: Array, default: null },
    publicationdate: { type: String, default: null },
  });

</script>

<template>
  <h2 class="text-lg text-txt-light">
    <font-awesome-icon :icon="['fas', 'check']" />
    {{ $t('The product has been added to the cart') }}
    <span
      v-if="orderablestatuses(publicationdate, inventories) === 1"
      class="text-md font-light"
    >
      {{ $t('cart.itemaddmodal.productaddedinfo.in-stock-alert') }}
    </span>
    <span
      v-if="orderablestatuses(publicationdate, inventories) === 2"
      class="text-md font-light"
    >
      {{ $t('cart.itemaddmodal.productaddedinfo.orderable-alert') }}
    </span>
    <span
      v-if="orderablestatuses(publicationdate, inventories) === 3"
      class="text-md font-light"
    >
      {{
        $t('cart.itemaddmodal.productaddedinfo.uncertain-availability-alert')
      }}
    </span>
    <span
      v-if="orderablestatuses(publicationdate, inventories) === 4"
      class="text-md font-light"
    >
      {{ $t('cart.itemaddmodal.productaddedinfo.preorder-alert') }}
    </span>
  </h2>
</template>
