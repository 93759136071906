<script setup>

  /**
   * 
   * Komponens: kosárban megjelenő inventory státuszok: figyelmeztető üzenet
   * 
  */

  const { inventories, publicationdate  } = defineProps({
    inventories: { type: Array, default: null },
    publicationdate: { type: String, default: null },
  });

</script>

<template>
  <div>
    <div
      v-if="orderablestatuses(publicationdate, inventories) === 1"
      class="font-sans font-light"
    >
      <p class="text-sm text-center">
        {{ $t('cart.itemaddmodal.availability.in-stock-alert') }}
      </p>
    </div>
    <div
      v-if="orderablestatuses(publicationdate, inventories) === 2"
      class="font-sans font-light"
    >
      <p class="text-sm text-center">
        {{ $t('cart.itemaddmodal.availability.orderable-alert') }}
      </p>
    </div>
    <div
      v-if="orderablestatuses(publicationdate, inventories) === 3"
      class="font-sans font-light"
    >
      <p class="text-sm text-center">
        {{ $t('cart.itemaddmodal.availability.uncertain-availability-alert') }}
      </p>
    </div>
    <div
      v-if="orderablestatuses(publicationdate, inventories) === 4"
      class="font-sans font-light"
    >
      <p class="text-sm text-center">
        {{ $t('cart.itemaddmodal.availability.preorder-alert') }}
      </p>
    </div>
  </div>
</template>
